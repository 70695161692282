import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import Menu from '../components/menuComponent';
import Process from '../components/processComponent';

export default () => {
  const dataImages = useStaticQuery(graphql`
    query ProcessQuery {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "images/process"}}) {
        edges {
          node {
            base
            childImageSharp {
              fluid(quality: 70, maxWidth: 400) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout
      title="The Process From the Initial Design Visit to Your Finished Fitted Furniture | Wow Interior Design"
      description="Find out how the Wow Interior Design design & installation process works from start to finish."
    >
      <Menu />
      <Process
        images={dataImages.allFile.edges}
      />
    </Layout>
  );
};

// import Layout from "../components/layout"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

// const data = useStaticQuery(graphql`
//   query {
//     file(relativePath: { eq: "images/Lisbon.jpg" }) {
//       childImageSharp {
//         # Specify a fixed image and fragment.
//         # The default width is 400 pixels
//         fixed {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `)
