import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';
import processStyles from './process.module.css';

const ProcessComponent = (props) => {
  const { images } = props;

  return (
    <div className={processStyles.processContainer}>
      <div className={processStyles.processHeaderTextContainer}>
        <div className={processStyles.processHeaderText}>
          <h1>Our Process</h1>
          <p>
            With years of experience designing homes across the South of England,
            the team at Wow Interior Design should be your first port of call if you are
            thinking of
            changing the way your home looks.
            We not only offer a fitted furniture service that is second to none, but we
            also take
            great pride in creating the homes that our customers have always dreamed of.
            If you are after a beautiful fitted bedroom, lounge, library, home cinema
            room or
            bespoke furniture, there is no better place to start than speaking to the
            team at Wow.
            You can be confident that our designers will make your dream room a reality.
          </p>
        </div>
      </div>
      <div className={processStyles.processHeaderContainer}>
        <div className={processStyles.processHeaderLine} />
        <div className={processStyles.processHeader}>How It Works</div>
        <div className={processStyles.processHeaderLine} />
      </div>
      <div className={processStyles.processSubHeader}>
        <p>A smooth installation from start to finish</p>
      </div>
      <div className={processStyles.processElementContainer}>
        <div className={processStyles.processElement}>
          <Img
            fluid={images[2].node.childImageSharp.fluid}
            alt="process image 1"
            fadeIn
          />
          <h3>Contact Us</h3>
          <p>
            Your free, no-obligation design meeting gives you the opportunity to
            discuss your ideas and see if they are realistic both practically
            and financially. To give you an accurate estimate of the work involved we will
            arrange an appropriate time to visit your home or business with you. One of our head
            designers will discuss all of your wants and needs with
            you and they will be able to suggest how we create your dream room in the most
            affordable way.
          </p>
        </div>
        <div className={processStyles.processElement}>
          <Img
            fluid={images[1].node.childImageSharp.fluid}
            alt="process image 2"
            fadeIn
          />
          <h3>Get Your Estimate</h3>
          <p>
            When visiting your property, one of our design team will come fully equipped and
            ready to measure your space in order to give you an accurate quote and a draft design
            will be created, showing how your new room will look. We can also showcase the great
            selection of colours and finishes on offer. Our range of furniture is diverse and
            fully bespoke, so we can offer our customers an endless array of design possibilities.
          </p>
        </div>
        <div className={processStyles.processElement}>
          <Img
            fluid={images[0].node.childImageSharp.fluid}
            alt="process image 3"
            fadeIn
          />
          <h3>We Start Work</h3>
          <p>
            Once you have selected your range, finish and price, it is time for you to put your feet
            up and watch the Wow fitting team do the hard work!
            Our fitters are clean, courteous and renowned for their professionalism.
            The work will be completed in a small timeframe without affecting the quality of the
            final product whilst constantly keeping you up to date with progress.
            Please check out our customer reviews!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProcessComponent;

ProcessComponent.propTypes = {
  images: PropTypes.array.isRequired,
};
